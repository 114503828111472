.contact{
    padding: 2rem 0;
}

/*----- 1- Contact Info -----*/
.contact .contact-info{
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background-color: var(--white);
}

.contact .contact-info .info-item{
    -webkit-box-flex: 1;
    -ms-flex:1 1 25rem;
        flex:1 1 25rem;
    height: 20rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.contact .contact-info .info-item .icon{
    height: 5rem;
    width: 5rem;
    font-size: 4rem;
    color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact .contact-info .info-item h3{
    font-size: 2rem;
    color: var(--black);
    padding: 1rem 0rem;
}

.contact .contact-info .info-item p{
    font-size: 1.6rem;
    color: var(--grey);
    padding-bottom: 0.5rem;
    text-transform: none;
}

/*----- 2- Contact Form -----*/
.contact .contact-form-container{
    width: 100%;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../assets/Background/Contact.jpg");
    background:         linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../assets/Background/Contact.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    padding: 4rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.contact .contact-form{
    background-color: var(--white);
    width: 70rem;
    padding: 3rem;
    border-radius: var(--border-radius);
}

.contact .contact-form h3{
    text-align: center;
    color: var(--black);
    font-size: 3rem;
    padding: 1rem 0;
}

.contact .contact-form .box{
    width: 100%;
    font-size: 1.6rem;
    color: var(--black);
    text-transform: none;
    background-color: var(--white);
    border: 0.15rem solid rgba(0, 0, 0, 0.2);
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
}

.contact .contact-form .box::placeholder{
    text-transform: capitalize;
    color: var(--grey);
}

.contact .contact-form .box:focus{
    border-color: var(--main-color);
}

.contact .contact-form textarea.box{
    height: 17rem;
    resize: none;
} 

.contact .contact-form button{
    background-color: transparent;
    margin-top: 1rem;
}

.contact .contact-form .alert{
    font-size: 2rem;
    color: var(--main-color);
    padding-left: 1rem;
    padding-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact .msg-alert{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1rem;
}

/*----- 3- Google Map -----*/
.contact iframe{
    width: 100%;
}