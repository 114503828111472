.counter{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; 
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; 
  gap: 4rem;
  margin-top: 5rem;
  border: 0.1rem solid var(--white);
  border-radius: var(--border-radius);
  padding: 2rem 5rem;
}

.counter-item{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.counter-item h3{
  color: var(--white);
  font-size: 6rem;
  font-weight: 500;
}

.counter-item span{
  display: block;
  font-size: 2rem;
  font-weight: 500;
  color: var(--white);
}