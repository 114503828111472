.register form .terms{
  display: -webkit-box;
  display: -ms-flexbox;  
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; 
  gap: .5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
} 
   
.register form .terms label{
  font-size: 1.5rem;
  color: var(--grey);
  cursor: pointer;
}
  
.register form .terms label span{
  color: var(--secondary-color);
}