.gallery-item{
    height: 30rem;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
} 

.gallery-item::before{
    content: '';
    position: absolute; 
    top: 0;
    left: 0; 
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    transition-delay: 0.25s;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    z-index: 1;
}

.gallery-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.gallery-item .content{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem);
    opacity: 0;
    z-index: 2;
}

.gallery-item .content h4{
    font-size: 3.5rem;
    font-weight: 500;
    color: var(--white);
    padding-bottom: 1.5rem;
}

.gallery-item .content .icon{
    display: inline-block;
    height: 4.5rem;
    width: 4.5rem;
    padding: 1.2rem;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	color: var(--white);
    background-color: var(--main-color);
}

.gallery-item .content .icon:hover{
    cursor: pointer;
    background-color: var(--black);
}

.gallery-item:hover::before{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition-delay: 0s;
}

.gallery-item:hover .content{
    opacity: 1;
    transition-delay: 0.3s;
    -webkit-transform: translateY(0);
            transform: translateY(0);
} 