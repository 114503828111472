.heading{
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  font-family: inherit;
}

.heading h2 {  
  font-size: 3rem;
  text-align: center; 
  line-height: 1.5em; 
  padding-bottom: 45px; 
  font-family: "Playfair Display", serif; 
  text-transform: uppercase;
  letter-spacing: 2px; 
  color: #111;
}

.heading h2:before {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 60%;
  left:50%; 
  margin-left:-30%;
  height: 1px;
  content: "";
  background-color: #777; 
  z-index: 4;
}

.heading h2:after {
  position:absolute;
  width:40px; 
  height:40px; 
  left:50%; 
  margin-left:-20px; 
  bottom:0px;
  content: '\00a7'; 
  font-size:30px; 
  line-height:40px; 
  color:var(--main-color);
  font-weight:400; 
  z-index: 5;
  display: block;
  background-color: var(--light-grey);
}