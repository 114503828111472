/*--------------------- 0- Global CSS (Start) ---------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root{
    --main-color: #0AA1DD;
    --black:#000000;
    --white: #fff;
    --grey: #666;
    --light-grey: #f7f7f7;
    --border-radius: 1rem;
    --border: 0.1rem solid rgba(0, 0, 0, 0.1);
    --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

*{
    font-family: 'Roboto', sans-serif;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-transform: capitalize;
    border: none;
    outline: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

*::selection{
    color: var(--white);
    background-color: var(--main-color);
}

body{
    background-color: var(--light-grey);
    overflow-x: hidden;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 5rem;
    scroll-behavior: smooth;
}

section{
    padding: 4rem 5%;
}

.homepage section:nth-child(odd){
    background-color: var(--white);
}

::-webkit-scrollbar{
    width: 1rem;
}

::-webkit-scrollbar-track{
    background: #333;
}

::-webkit-scrollbar-thumb{
    background: var(--main-color);
}


/*----- 1- Form CSS -----*/
.form{
    padding: 5rem;
    background: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    margin: 1rem auto;
    text-align: center;
    border: var(--border);
    border-radius: var(--border-radius);
}

.form.account{
    max-width: 45rem;
}
  
.form h3{ 
    font-size: 2.5rem;
    font-weight: 500;
    padding-bottom: 1rem;
    color: #333;
    text-transform: uppercase;
    text-align: center;
}
  
.form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
  
.form .input-box .box{
    width: 49%;
}
  
.form .box{
    width: 100%;
    padding: 1.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius);
    font-size: 1.6rem;
    color: var(--grey);
    text-transform: none;
    margin: 0.7rem 0;
}

.form .box::placeholder{
    text-transform: capitalize;
}

.form .box:focus{
    border-color: var(--main-color);
}

.form p{
    padding-top: 2rem;
    font-size: 1.5rem;
    color: var(--grey);
    text-align: center;
    padding-bottom: 1rem;
}

.form .link{ 
    font-size: 1.5rem;
    color: var(--main-color);
}

.form .link:hover{
    text-decoration: underline;
} 

.form button{
    width: 100%;
    margin-top: 1rem; 
    background-color: var(--white);
}

.form .btn{
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
}

.form textarea{
    resize: none;
    height: 20rem;
}

/*----- 2- Sidebar CSS -----*/
/*
1- Search
2- Category
3- Recent Items
4- Tags
*/

.sidebar{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem; 
        flex: 1 1 25rem;  
}

.sidebar .sidebar-item{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    margin-bottom: 2rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.sidebar .box-container{
    padding: 1.5rem;
}

/*-- 1- Search --*/
.sidebar .search .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 5rem;
    overflow: hidden;
}

.sidebar .search .input-box input{
    width: 100%;
    padding: 1.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-bottom-left-radius: 5rem;
    border-top-left-radius: 5rem;
}

.sidebar .search .input-box input:focus{
    border-color: var(--main-color);
}

.sidebar .search .input-box label{
    color: var(--white);
    background-color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 2rem;
    font-size: 1.8rem;
    cursor: pointer;
}

.sidebar .search .input-box label:hover{
    background-color: var(--black);
}

/*-- 2- Category --*/
.sidebar .category .item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.2rem 0;
}

.sidebar .category .item a{
    font-size: 1.7rem;
    color: #666;
    font-weight: 400;
    cursor: pointer;
}

.sidebar .category .item a:hover{
    color: var(--main-color);
}

.sidebar .category .item p{
    font-size: 1.8rem;
    color: #666;
}

/*-- 3- Recent Post --*/
.sidebar .recent-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

/*-- 4- Tags --*/
.sidebar .tags .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

.sidebar .tag-item{
    border: 0.15rem solid rgba(0, 0, 0, 0.1);
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
    border-radius: var(--border-radius);
}

.sidebar .tag-item:hover{
    background-color: var(--main-color);
    color: var(--white);
    cursor: pointer;
}


/*----- 3- Swiper Pagination CSS -----*/
.swiper-pagination-bullet{ 
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    background: #333;
    opacity: 0.7;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.swiper-pagination-bullet-active{
    width: 3rem;
    background: var(--main-color) !important;
    opacity: 1 !important;
}


/*--------------------- Responsive (Start) ---------------------*/
@media (max-width: 991px){
    html{
      font-size: 55%;
    }
  
    .blog.grid, .blog.list{
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
    }
  
  }
  
  @media (max-width: 768px){
  
    section, .header, footer{
        padding: 2rem;
    }

    .home .content{
        width: 95%;
    }

    .testimonial-slider .swiper-button-next{
        right: 0rem;
    }
      
    .testimonial-slider .swiper-button-prev{
        left: 0rem;
    }

    .testimonial-slider .swiper{
        padding: 2rem 8rem 4rem 8rem;
    }
  
  }
  
  @media (max-width: 450px){
  
    html{
        font-size: 50%;
    }
  
    section, .header, footer{
        padding: 1rem;
    }

    .speech-item{
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
  
    .speech-item .timing{
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    }
  
    .form .input-box .box{
      width: 100%;
    }

    .contact .form{
        width: 100%;
    }
  
  }
  
  /*--------------------- Responsive (End) ---------------------*/
  