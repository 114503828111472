.footer{
    padding: 6rem 2rem;
    background-color: var(--black);
    text-align: center;
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1.5rem; 
}

.footer-item .logo h2{
    color: var(--main-color);
    padding-bottom: 0.5rem;
}

.footer-item p{
    max-width: 60rem;
    color: var(--white);
    font-size: 1.6rem;
    line-height: 1.6;
}

.footer-item .social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 1rem;
}

.footer-item .social a{
    display: inline-block;
    height: 4rem;
    width: 4rem;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
}

.footer-item .social a .icon{
    color: var(--main-color);
    font-size: 2rem;
}

.footer-item .social a:hover{
    background-color: var(--main-color);
}

.footer-item .social a:hover .icon{
    color: var(--white);
}

.footer-item .info{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
}

.footer-item .info a{
    display: block;
    font-size: 1.8rem;
    color: var(--white);
    border: 0.1rem solid var(--white);
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
}

.footer-item .info a:hover{
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.footer .content p{
    font-size: 2rem;
    color: var(--white);
}

.footer .content p span{
    color: var(--main-color);
}