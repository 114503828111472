.speaker-single .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 2rem;
}

.speaker-single .image{
    -webkit-box-flex: 1; 
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    text-align: center;
}

.speaker-single .image img{
    height: 40rem;
    width: 30rem;
    object-fit: cover;
    border-top-left-radius: 10rem;
    border-bottom-right-radius: 10rem;
    overflow: hidden;
}

.speaker-single .social-connect{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
}

.speaker-single .social-connect a{
    font-size: 2.5rem;
    color: var(--grey);
}

.speaker-single .social-connect a:hover{
    color: var(--main-color);
}

.speaker-single .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 60rem;
        flex: 1 1 60rem;
}

.speaker-single .content h2{
    font-size: 4rem;
    color: var(--black);
    padding-bottom: 0.5rem;
}

.speaker-single .content h3{
    font-size: 2rem;
    color: var(--main-color);
    padding-bottom: 1rem;
}

.speaker-single .content h5{
    font-size: 2.5rem;
    color: var(--black);
    padding-top: 1rem;
}

.speaker-single .content p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.6;
    padding-bottom: 1rem;
}

.speaker-single .content p span{
    color: var(--black);
    padding-left: 1rem;
    font-style: normal;
}

.speaker-testimonials p{
    font-style: italic;
}