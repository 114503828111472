.venue{
    padding: 2rem 0;
}

.venue .box-container{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("../../../assets/Background/Venue.jpg");
    background:         linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("../../../assets/Background/Venue.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    padding: 2rem 5%;
}
  
.venue .venue-info{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
}

.venue .venue-info .info-item{
    -webkit-box-flex: 1;
    -ms-flex:1 1 30rem;
        flex:1 1 30rem;
    border-radius: var(--border-radius);
    background-color: var(--white);
    height: 20rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.venue .venue-info .info-item .icon{
    height: 5rem;
    width: 5rem;
    font-size: 4rem;
    color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.venue .venue-info .info-item h3{
    font-size: 2rem;
    color: var(--black);
    padding: 1rem 0rem;
}

.venue .venue-info .info-item p{
    font-size: 1.6rem;
    color: var(--grey);
    padding-bottom: 0.5rem;
    text-transform: none;
}

/*----- 2- Google Map -----*/
.venue iframe{
    width: 100%;
    height: 40rem;
    border-radius: var(--border-radius);
}