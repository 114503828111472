.home{ 
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-pack: center; 
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1px;
  min-height: 100vh;
  padding-top: 8rem;
  position: relative;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("../../../assets/Home/Home.jpg");
  background:         linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("../../../assets/Home/Home.jpg");
  background-position: center;
  background-size: cover;
}

.home img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home .content{
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.home .content .text{
  text-align: center;
  max-width: 70rem;
}

.home .content h1{
  color:var(--white);
  font-size: 4.5rem;
  font-weight: 500;
}

.home .content p{
  color: var(--white);
  font-size: 2rem;
  line-height: 1.5;
  padding: 2rem 0;
}

.home .content .btn-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1rem;
}

.home .content .btn:first-child{
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}

.home .content .btn:first-child:hover{
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.home .content .btn:last-child{
  background-color: var(--main-color);
  color: var(--white);
}

.home .content .btn:last-child:hover{
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}