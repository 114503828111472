.faq .accordion{
    background: var(--white);
    margin-bottom: 1.5rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
} 
  
.faq .accordion:last-child{
    margin-bottom: 0rem; 
}   

.faq .accordion .accordion-heading{
    padding: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 1rem;
    background: var(--main-color);
    cursor: pointer;
}

.faq .accordion .accordion-heading h3{
    font-size: 2rem;
    color: var(--white);
}

.faq .accordion .accordion-heading .icon{
    font-size: 2rem;
    color: var(--white);
}

.faq .accordion.active .accordion-content{
    display: block;
}

.faq .accordion-content{
    padding: 2rem;
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
    display: none;
}
