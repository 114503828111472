.header{
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify; 
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center; 
    align-items: center;
    height: 8rem;
    width: 100%;
    z-index: 1000;
    padding: 0rem 5%;
    background-color: transparent
}

.header.active{
    position: fixed;
    background-color: var(--main-color);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}
 
.header .menu-btn{
    display: none;
    height: 4rem; 
    font-size: 2.5rem;
    color: var(--white);
    border: 0.1rem solid var(--white);
    border-radius: var(--border-radius);
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.header .menu-btn:hover{
    color: var(--main-color);
    border-color: var(--main-color);
}

.header.active .menu-btn:hover{
    color: var(--black);
    border-color: var(--black);
}

.header .navbar{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1.5rem;
    height: 100%;
}

.header .buy-ticket{
    display: inline-block;
    font-size: 1.8rem;
    color: var(--white);
    background-color: var(--main-color);
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: 5rem;
}

.header.active .buy-ticket{
    background-color: var(--black);
}

.mobile-menu{
    display: none;
}


/*---------------- RESPONSIVE ----------------*/
@media (max-width: 991px){
    .header .menu-btn{
        display: block;
    } 
    
    .mobile-menu{
        display: block;
    }

    .header .navbar{
        display: none;
    }
}