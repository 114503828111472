.page-title{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
                url("../../assets/PageTitle/PageTitle.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
                url("../../assets/PageTitle/PageTitle.jpg");
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center; 
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    min-height: 40rem;
}

.page-title h2{
    color: var(--white);
    font-size: 4rem;
    position: relative;
    padding-top: 4rem;
}

.page-title h2::before{
    content: '';
    position: absolute;
    bottom: -1rem; 
    left: 10%;
    background: var(--main-color);
    height: 0.3rem;
    width: 80%;
}

.page-title p{
    padding-top: 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--white);
}

.page-title p a{
    color: var(--main-color);
}

.page-title p a:hover{
    text-decoration: underline;
}
