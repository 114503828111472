.about .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 4rem;
} 

.about .content{ 
    -webkit-box-flex: 1;
    -ms-flex: 1 1 42rem;
        flex: 1 1 42rem;
}

.about .content h2{
    color: var(--black);
    font-size: 4rem;
    letter-spacing: 0.1rem;
    margin-bottom: 2rem;
}

.about .content h5{
    color: var(--black);
    font-size: 1.8rem;
    line-height: 1.5;
    padding-bottom: 1rem;
}

.about .content p{
    color: var(--grey);
    font-size: 1.6rem;
    line-height: 1.8;
    padding-bottom: 1.5rem;
}

.about .content .btn{
    margin-top: 1rem;
} 

.about .services{
    list-style-type: none;
    padding-bottom: 2rem;
}

.about .services li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    color: var(--grey);
    padding-bottom: 0.5rem;
}

.about .services li .icon{
    color: var(--main-color);
}

.about .image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 42rem;
        flex: 1 1 42rem;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
}

.about .image-1,
.about .image-2{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
    text-align: center;
}

.about .image .image-item{
    height: 25rem;
    width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.about .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}