.logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
 
.logo img{
    height: 4rem;
    object-fit: cover;
}

.logo h2{
    color: var(--white);
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem; 
}