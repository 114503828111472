.blog-single{
    display: -webkit-box;  
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    gap: 2rem;
    -ms-flex-wrap: wrap; 
        flex-wrap: wrap;  
}  

.blog-single .blog-container{ 
    -webkit-box-flex: 1;
    -ms-flex: 1 1 75rem;
        flex: 1 1 75rem;
}

/*----- 1- Blog Info -----*/
.blog-info{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
  } 
    
.blog-info .image{ 
    position: relative;
    height: 40rem;
}
  
.blog-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.blog-info .date{
    position: absolute; 
    bottom: 0rem;
    left: 0rem;
    width: 7rem;
    color: var(--white);
    background-color: var(--main-color);
    opacity: 0.9;
    font-size: 2rem;
    padding: 2rem;
    text-align: center;
}

.blog-info .content{
    padding: 2rem;   
}

.blog-info .content .main-heading{
    display: inline-block;
    font-size: 3rem;
    font-weight: bold;
    color: var(--black);
    padding-bottom: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.blog-info .details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.blog-info .details h5{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--main-color);
    border-right: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 0.5rem 1rem;
}

.blog-info .details h5:first-child{
    padding-left: 0;
}

.blog-info .details h5:last-child{
    border-right: none;
}

.blog-info .details h5 span{
    padding-left: 0.5rem;
}
  
.blog-info p{
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}
  
.blog-info .important{
    padding: 2rem 4rem;
    font-size: 1.5rem;
    color:  var(--black);
    line-height: 1.8;
    font-style: italic;
    border-left: 0.4rem solid var(--main-color);
    margin-bottom: 2rem;
}

.blog-info .blog-gallery{ 
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(20rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 0.5rem;
    padding: 2rem 0; 
}

.blog-info .blog-gallery img{
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

.blog-info .end-details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}

.blog-info .tags, 
.blog-info .share{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

.blog-info .tags h3,
.blog-info .share h3{
    color: var(--black);
    font-size: 1.8rem;
}

.blog-info .tags span,
.blog-info .share .icon{
    color: var(--grey);
    font-size: 1.6rem;
}

.blog-info .share .icon:hover{
    color: var(--main-color);
    cursor: pointer;
}

/*----- 2- Leave Reply -----*/
.leave-reply{
    width: 100%;
} 