.comments{
    background-color: var(--white);
    padding: 2rem;
    margin: 2rem 0; 
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
} 
 
.comments h4{   
    font-size: 2rem;  
    color: var(--black); 
    padding-bottom: 2rem;
} 

.comments .replies{
    margin-left: 10rem;
}