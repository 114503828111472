.schedule .schedule-item{
    margin: 2rem 0;
} 

.schedule .schedule-item h1{
    font-size: 3rem;
    font-weight: 500;
    color: var(--black);
    text-align: center;
    padding-bottom: 1rem;
}

.schedule .schedule-item h4{
    font-size: 3rem;
    font-weight: 500;
    color: var(--main-color);
    text-align: center;
    padding-bottom: 2rem;
}

.speech-item{
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}

.speech-item.break{
    background-color: var(--main-color);
}

.speech-item.break .timing{
    color: var(--white);
    border-color: var(--white);
} 

.speech-item.break .speaker-intro{
    display: none;
}

.speech-item.break .content h3{
    font-size: 2rem;
    font-weight: 500; 
    color: var(--white);
}

.speech-item.break p{
    display: none;
}

.speech-item .timing{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 15rem;
        flex: 1 1 15rem;
    text-align: center;
    padding: 2rem 0rem;
    font-size: 2rem;
    color: var(--black);
    border-right: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.speech-item .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 80rem;
        flex: 1 1 80rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
}

.speech-item .speaker-intro img{
    height: 15rem;
    width: 12rem;
    object-fit: cover;
}

.speech-item .speaker-intro h5{
    font-size: 2rem;
    font-weight: 400;
    padding-top: 1rem;
}

.speech-item .topic h3{
    font-size: 3rem;
    color: var(--black);
}

.speech-item .topic p{
    font-size: 1.7rem;
    line-height: 1.5;
    color: var(--grey);
    padding-top: 1rem;
}