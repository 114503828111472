.sponsors-container{
    margin: 2rem 0;
} 

.sponsors-container h3{
    font-size: 3.5rem;
    color: var(--main-color);
    text-align: center;
    margin-bottom: 1.5rem; 
} 

.sponsors-container .content{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(20rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 1.5rem;
}

.sponsor-item{
    min-height: 10rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--white);
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.sponsor-item img{
    height: 80%;
    width: 80%;
    object-fit: cover;
} 

.sponsors .btn{
    margin-top: 2rem;
}
