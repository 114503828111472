.ticket .box-container{
    display: -ms-grid; 
    display: grid;
    -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 2rem;
}
  
.ticket-item{
    -webkit-box-flex: 1; 
    -ms-flex:1 1 33rem; 
        flex:1 1 33rem;
    background-color: var(--white); 
    text-align: center;
    padding: 2rem 0;
    border: var(--border);
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.ticket-item:hover{
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
}
   
.ticket-item h3{
    color: var(--white);
    background: var(--main-color);
    font-size: 3rem;
    padding: 1.5rem 0;
    margin: 1rem 0;
}

.ticket-item .price{
    color: var(--black);
    font-size: 5rem;
    font-weight: bolder;
}
  
.ticket-item .price span{
    font-size: 2rem;
    font-weight: normal;
}
  
.ticket-item ul{
    list-style: none;
    padding:2rem 0;
}
  
.ticket-item ul li{
    padding: 0.7rem 0;
    font-size: 1.7rem;
    color: var(--grey);
}
  
.ticket-item .btn{
    margin-top: 1rem;
    border-radius: 5rem;
}