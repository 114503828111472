.schedule-btn-tabs .button{
    display: inline-block;
	padding: 2rem 3rem;
	color: var(--black);
    background-color: var(--white);
	font-size: 2.5rem;
	letter-spacing: 0.1rem; 
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; 
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-radius: 0.5rem;
}

.schedule-btn-tabs .button.active,
.schedule-btn-tabs .button:hover{
    color: var(--white);
    background-color: var(--main-color);
    cursor: pointer;
}