.speaker-item{
  text-align: center;
  border: var(--border);
  padding-top: 2rem;
  border-radius: var(--border-radius);
  overflow: hidden;
  background: var(--white);
} 
 
.speaker-item:hover{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.speaker-item .image{
  margin: 0 auto;
  height: 20rem;
  width: 20rem;
  overflow: hidden;
  border-radius: 50%;
}

.speaker-item .image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.speaker-item .content{
  text-align: center;
  padding: 2rem;
}

.speaker-item .content h3{
  color: var(--black);
  font-size: 2.5rem;
}

.speaker-item .content p{
  color: var(--main-color);
  font-size: 1.8rem;
  padding: 1rem 0;
}

.speaker-item .social-connect{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 1rem;
  gap: 0.5rem;
}

.speaker-item .social-connect a{
  height: 4rem;
  width: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.8rem;
  border-radius: 50%;
  color: var(--main-color);
  border: 0.1rem solid var(--main-color);
}

.speaker-item .social-connect a:hover{
  color: var(--white);
  background-color: var(--main-color);
}
