.blog-item{
  background-color: var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  overflow: hidden; 
}
 
.blog-item .date{
  position: absolute; 
  bottom: 0rem;
  left: 0rem;
  width: 7rem;
  color: var(--white);
  background-color: var(--main-color);
  opacity: 0.9;
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
}

.blog-item .image{
  position: relative;
  height: 25rem;
}

.blog-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-item .content{
  padding: 2rem;  
}

.blog-item .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--black);
  padding-top: 1rem;
}

.blog-item .content .main-heading:hover{
  color: var(--main-color);
}

.blog-item p{
  padding: 1.5rem 0;
  font-size: 1.6rem;
  color: var(--grey);
  line-height: 1.5; 
}