.comment{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start; 
    -ms-flex-align: start; 
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    gap: 2rem;
    padding: 1rem 0; 
}

.comment .image{
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    overflow: hidden;
}

.comments .image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.comments .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

.comments .intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.comment .intro h6{
    font-size: 2rem;
    color: var(--black);
}

.comment .intro p{
    font-size: 1.5rem;
    color: var(--main-color);
    padding: 0.5rem 0;
}

.comment .icon-item{
    color: var(--black);
    font-size: 1.6rem;
}

.comment .icon-item:hover{
    cursor: pointer;
    color: var(--main-color);
}

.comment .text{
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}