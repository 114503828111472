.btn{
    display: inline-block;
	padding: 1rem 3rem;
	color: var(--main-color); 
    background-color: transparent;
    border: 0.2rem solid var(--main-color);
	font-size: 1.8rem;
	letter-spacing: 0.1rem;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s; 
    transition: 0.5s;
    border-radius: var(--border-radius);
}

.btn:hover{
    color: var(--white);
    background-color: var(--main-color);
    cursor: pointer;
}